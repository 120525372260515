import { RESET_VALUES, UPDATE_MAX_VALUE, UPDATE_MIN_VALUE } from './action-types';

export const updateMinValue = (dispatch, payload) => {
  dispatch({ type: UPDATE_MIN_VALUE, payload });
};

export const updateMaxValue = (dispatch, payload) => {
  dispatch({ type: UPDATE_MAX_VALUE, payload });
};

export const resetSliderValues = (dispatch, payload) => {
  dispatch({ type: RESET_VALUES, payload });
};
