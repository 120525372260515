import { useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';

export const usePageWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = _debounce(() => setWidth(window.innerWidth), 100);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  });

  return { width };
};
