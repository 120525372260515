import { SUCCESS } from '../../constants';
import {
  COMPLETE_BOOKING,
  CREATE_BOOKING,
  GET_DEPOSIT,
  LOAD_BOOKING,
  SET_DISCOUNT_CODE,
  SET_DISCOUNT_VALUE,
  STEP_COMPLETED,
  STEP_NOT_COMPLETED,
  CUSTOM_BOOKING_PAYMENT,
  CUSTOM_BOOKING_PAYMENT_ERR,
  RESET_STEPS_COMPLETED,
} from './action-types';

const initialState = {
  pendingParams: {},
  type: undefined,
  channelId: undefined,
  unitId: undefined,
  unitTypeId: undefined,
  unitPrice: undefined, // new bookings only
  totalCost: undefined, // existing bookings only
  amountPaid: undefined, // exiting bookings only
  parkId: undefined,
  arrivalDate: undefined,
  duration: undefined,
  bookingId: undefined,
  message: undefined,
  deposit: undefined,
  compulsoryDeposit: undefined,
  depositText: undefined,
  discountApplied: false,
  discountCode: '',
  discountValue: 0,
  dueDate: undefined,
  stepsCompleted: [],
  confirmationId: undefined,
  customBookingPayment: undefined,
  customBookingPaymentErr: false,
  paidWithFullVoucher: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOKING:
      return {
        ...initialState,
        pendingParams: action.payload,
      };
    case CREATE_BOOKING + SUCCESS:
      return {
        ...state,
        pendingParams: {},
        ...state.pendingParams,
        message: action.payload.response.data.data.booking.message,
        bookingId: action.payload.response.data.data.booking.bookId || action.payload.response.data.data.booking.bookID, // Hack for backend bug where we get bookID instead of bookId
      };
    case LOAD_BOOKING:
      return {
        ...initialState,
        ...action.payload,
      };
    case GET_DEPOSIT + SUCCESS: {
      const responseData = action.payload.response.data.data;
      // The FE treats this as a positive number, BE returns as negative, so convert it
      const discountValue = responseData.discountValue * -1;
      return {
        ...state,
        deposit: parseFloat(responseData.deposit) || null,
        depositText: responseData.depositMsg,
        dueDate: responseData.dueDate,
        compulsoryDeposit: responseData.compulsoryDeposit,
        discountCode: responseData.discountCode,
        discountValue,
        discountApplied: discountValue > 0,
      };
    }
    case SET_DISCOUNT_CODE:
      return {
        ...state,
        discountCode: action.payload,
      };
    case SET_DISCOUNT_VALUE: {
      const discountValue = action.payload.data;
      const extrasPrice = action.payload.extrasPrice;
      // Voucher covers the deposit for euro holidays, whole price otherwise
      const isEuroHoliday = state.compulsoryDeposit === 1;
      const holidayBaseCost = isEuroHoliday ? state.deposit : state.unitPrice;
      const holidayCost = Number(holidayBaseCost) + Number(extrasPrice);
      const paidWithFullVoucher = Number(discountValue) === Number(holidayCost);

      return {
        ...state,
        discountValue,
        discountApplied: discountValue > 0,
        paidWithFullVoucher,
        deposit: isEuroHoliday ? 0 : state.deposit,
      };
    }
    case RESET_STEPS_COMPLETED:
      return {
        ...state,
        stepsCompleted: [],
      };
    case STEP_COMPLETED:
      return {
        ...state,
        stepsCompleted: [...new Set([...state.stepsCompleted, action.payload])],
      };
    case STEP_NOT_COMPLETED:
      return {
        ...state,
        stepsCompleted: state.stepsCompleted.filter((item) => item !== action.payload),
      };
    case COMPLETE_BOOKING:
      return {
        ...state,
        bookingId: undefined,
        confirmationId: state.bookingId,
      };
    case CUSTOM_BOOKING_PAYMENT:
      return {
        ...state,
        customBookingPayment: action.payload,
      };
    case CUSTOM_BOOKING_PAYMENT_ERR:
      return {
        ...state,
        customBookingPaymentErr: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
