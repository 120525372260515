import { MAX_VALUE_ON_PRICE_RANGE, MIN_VALUE_ON_PRICE_RANGE } from '../../../Constants';
import { UPDATE_MAX_VALUE, UPDATE_MIN_VALUE, RESET_VALUES } from './action-types';

const initialState = {
  rangeSliderMin: MIN_VALUE_ON_PRICE_RANGE,
  rangeSliderMax: MAX_VALUE_ON_PRICE_RANGE,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MAX_VALUE:
      return {
        ...state,
        rangeSliderMax: Math.min(action.payload, MAX_VALUE_ON_PRICE_RANGE),
      };
    case UPDATE_MIN_VALUE:
      return {
        ...state,
        rangeSliderMin: Math.max(action.payload, 0),
      };
    case RESET_VALUES: 
      return initialState
    default:
      return state;
  }
};

export default reducer;
