import { CREATE_USER, GET_BOOKINGS, LOGIN, LOGOUT, UPDATE_USER } from './action-types';
import { GET_INIT_DATA } from '../init/action-types';
import { SUCCESS } from '../constants';
import { COMPLETE_BOOKING } from '../booking/booking/action-types';

const initialState = {
  loggedIn: false,
  bookings: undefined,
  details: {},
  selectedContactPreferencesInBooking: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_DATA + SUCCESS:
      if (action.payload.response.data.data.account) {
        return {
          ...state,
          loggedIn: true,
          details: {
            ...action.payload.response.data.data.account,
            dobMnth: parseInt(action.payload.response.data.data.account.dobMnth).toString(), // Hack to work around padded values from server
          },
        };
      } else {
        return initialState;
      }
    case CREATE_USER + SUCCESS:
    case UPDATE_USER + SUCCESS:
      localStorage.setItem('hasAccountData', true);
      return {
        ...state,
        loggedIn: true,
        selectedContactPreferencesInBooking: window.location.href.indexOf('booking') > -1 ? true : false,
        details: {
          ...action.payload.response.data.data,
          dobMnth: parseInt(action.payload.response.data.data.dobMnth).toString(), // Hack to work around padded values from server
        },
      };
    case LOGIN + SUCCESS:
      localStorage.setItem('hasAccountData', true);
      return {
        ...state,
        loggedIn: true,
        selectedContactPreferencesInBooking: false,
        details: {
          ...action.payload.response.data.data,
          dobMnth: parseInt(action.payload.response.data.data.dobMnth).toString(), // Hack to work around padded values from server
        },
      };
    case LOGOUT + SUCCESS:
      localStorage.setItem('hasAccountData', false);
      return initialState;
    case GET_BOOKINGS + SUCCESS:
      return {
        ...state,
        bookings: action.payload.response.data.data.bookings,
      };
    case COMPLETE_BOOKING:
      return {
        ...state,
        bookings: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
