import PropTypes from 'prop-types';
import { Button, Col, Container, NavDropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BREAKPOINTS } from '../../Constants';
import { usePageWidth } from '../../hooks/usePageWidth';
import { HeaderColumn } from './HeaderColumn';
import { useShowOriginalBackground } from './useShowOriginalBackground';

export const HeaderNavDropdown = ({
  channelOpen,
  showDropdownOnDesktop,
  handleDropdownClick,
  hideDropdownOnDesktop,
  channel,
  handleCloseNavbars,
  title,
  columns,
  isChannel,
  image,
}) => {
  const { showOriginalBackground } = useShowOriginalBackground();

  const handleHeaderClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    e.stopPropagation(); // Stop event from propagating
    handleDropdownClick(e);
  };

  const { width } = usePageWidth();
  const mobileDropdown = width < BREAKPOINTS.lg;

  return (
    <NavDropdown
      title={mobileDropdown ? title : <span onClick={handleHeaderClick}>{title}</span>}
      className="megamenu"
      show={channelOpen === channel}
      onClick={mobileDropdown ? handleHeaderClick : undefined}
      onMouseEnter={showDropdownOnDesktop}
      onMouseLeave={hideDropdownOnDesktop}
    >
      <Container className="d-block p-3 rounded-bottom megamenu-panel"  onMouseLeave={hideDropdownOnDesktop}>
        <Row noGutters className="w-100">
          <Col className="d-flex flex-column">
            <Row>
              {columns.map((col, i) => (
                <Col key={i} xs={12} md={6} lg={4}>
                  {col.map((section, i) => (
                    <HeaderColumn
                      key={i}
                      section={section}
                      handleCloseNavbars={handleCloseNavbars}
                      channelId={channel}
                    />
                  ))}
                </Col>
              ))}
            </Row>
            {isChannel && (
              <Row className="mt-auto d-lg-none">
                <Col>
                  <Link to={`/region/${channel}`}>
                    <Button className="browse-all mb-3 px-3" variant={showOriginalBackground ? 'secondary' : 'primary'}>
                      Browse all {title}
                    </Button>
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
          {image && (
            <Col md={5} lg={4} className="d-flex align-items-start">
              <Link to={`/region/${channel}`}>
                <img src={image} className="w-100 rounded" alt={title} />
              </Link>
            </Col>
          )}
        </Row>
      </Container>
    </NavDropdown>
  );
};

HeaderNavDropdown.propTypes = {
  channelOpen: PropTypes.string,
  channel: PropTypes.string,
  showDropdownOnDesktop: PropTypes.func,
  handleDropdownClick: PropTypes.func,
  hideDropdownOnDesktop: PropTypes.func,
  handleCloseNavbars: PropTypes.func,
  title: PropTypes.string,
  columns: PropTypes.array,
  isChannel: PropTypes.bool,
  image: PropTypes.string,
};
