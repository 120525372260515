import { useDayPicker } from 'react-day-picker';
import { NextArrowImg, PreviousArrowImg } from '../../Constants';

export const CustomPreviousButton = ({ onClick }) => {
  const { previousMonth } = useDayPicker();
  if (!previousMonth) return null;
  return (
    <div
      onClick={onClick}
      className="custom-prev-button-container"
      style={{
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginRight: '4px',
      }}
      role="button"
      aria-label="Previous Month"
    >
      <img src={PreviousArrowImg} alt="Previous Month" style={{ height: '14px' }} />
    </div>
  );
};

export const CustomNextButton = ({ onClick }) => {
  const { nextMonth } = useDayPicker();
  if (!nextMonth)
    return (
      <div
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    );
  return (
    <div
      onClick={onClick}
      className="custom-next-button-container"
      style={{
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      role="button"
      aria-label="Next Month"
    >
      <img src={NextArrowImg} alt="Next Month" style={{ height: '14px' }} />
    </div>
  );
};
