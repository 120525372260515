import { GET_INIT_DATA } from '../../init/action-types';
import { GET_FILTERS } from './action-types';
import { SUCCESS } from '../../constants';

const initialState = {
  filters: {},
};

const getFilters = (filters) => {
  const newFilters = filters.map((f, i) => {
    return { ...f, id: i, type: 'compare' in f ? 'nFilt' : 'bFilt' };
  });
  return newFilters;
};

const fixFilterGroups = (fg) => {
  // TODO: Delete this when filterGroups already includes 5
  if (!Object.keys(fg).includes('5')) fg['5'] = {};
  return fg;
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_DATA + SUCCESS:
    case GET_FILTERS + SUCCESS:
      if (action.payload.response.data.data.Filters) {
        return {
          filters: {
            ...state.filters,
            ...getFilters(action.payload.response.data.data.Filters),
          },
          bFilters: {
            ...state.bFilters,
            ...action.payload.response.data.data.bFilters,
          },
          nFilters: {
            ...state.nFilters,
            ...action.payload.response.data.data.nFilters,
          },
          filterGroups: {
            ...state.filterGroups,
            ...fixFilterGroups(action.payload.response.data.data.filterGroups),
          },
        };
      }
      return state;
    default:
      return state;
  }
};

export default reducer;
