import { FAILURE, SUCCESS } from '../../constants';
import { START_SEARCH } from '../form/action-types';
import { GET_RESULTS } from './action-types';

const initialState = {
  pendingParams: {},
  params: {},
  requestPayload: {},
  count: 0,
  results: [],
  isSearching: false,
  isWaiting: false,
  searchId: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SEARCH:
      return {
        ...state,
        pendingParams: { ...action.payload },
        count: 0,
        results: [],
        requestPayload: {},
        searchId: 0,
      };
    case START_SEARCH + SUCCESS:
      return {
        ...state,
        pendingParams: {},
        requestPayload: { ...action.payload.params },
        params: { ...state.pendingParams },
        isSearching: true,
      };
    case START_SEARCH + FAILURE:
      return {
        ...state,
        pendingParams: {},
        isSearching: false,
      };
    case GET_RESULTS + SUCCESS: {
      if (!action.payload.response.data.data.wait) {
        return {
          ...state,
          count: action.payload.response.data.data.count,
          results: [...action.payload.response.data.data.results],
          isSearching: false,
          isWaiting: false,
          searchId: action.payload.response.data.data.searchId,
        };
      } else {
        return {
          ...state,
          count: action.payload.response.data.data.count,
          isWaiting: true,
        };
      }
    }
    default: {
      return state;
    }
  }
};

export default reducer;
