import { config, images } from '../../config';

function Logo({
  alt = config['global.brandName'],
  variant = 'light',
  logoLight = images.logoLight,
  logoDark = images.logoDark,
  height = 32,
  className = '',
}) {
  return <img src={variant === 'dark' ? logoDark : logoLight} height={height} className={className} alt={alt} />;
}

export default Logo;
