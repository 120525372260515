import { useDispatch, useSelector } from 'react-redux';

import { requestPasswordReset } from '../../../../redux/user/actions';
import {
  emailSelector,
  loggedInSelector,
  requestPasswordResetErrorSelector,
  requestPasswordResetFailedSelector,
  requestPasswordResetPendingSelector,
} from '../../../../redux/user/selectors';

import { validateFields } from './PasswordResetForm';
import { useLocalFormState } from '../../../../hooks/useLocalFormState';

export const usePasswordResetFormActions = () => {
  const dispatch = useDispatch();
  return {
    requestPasswordReset: (email) => {
      return requestPasswordReset(dispatch, email);
    },
  };
};

export const usePasswordResetFormState = () => {
  const loggedInEmail = useSelector(emailSelector);
  const lfs = useLocalFormState(validateFields);
  const isLoggedIn = useSelector(loggedInSelector);

  if (isLoggedIn && lfs.values.email !== loggedInEmail) {
    lfs.onChange('email', loggedInEmail);
  }

  return {
    ...lfs,
    loggedIn: isLoggedIn,
    requestPasswordResetError: useSelector(requestPasswordResetErrorSelector),
    requestPasswordResetFailed: useSelector(requestPasswordResetFailedSelector),
    requestPasswordResetPending: useSelector(requestPasswordResetPendingSelector),
  };
};

export default usePasswordResetFormState;
