import { urls } from '../../../../config';
import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import {
  getOperatorRegionsHasFailedSelector,
  getOperatorRegionsIsLoadedSelector,
  getOperatorRegionsIsLoadingSelector,
  getRegionsHasFailedSelector,
  getRegionsIsLoadedSelector,
  getRegionsIsLoadingSelector,
} from './selectors';

export const getOperatorRegions = (dispatch, channelId, operatorId, force = false) => {
  dispatch((d, getState) => {
    const state = getState();
    const isLoaded = getOperatorRegionsIsLoadedSelector(state, channelId, operatorId);
    const isLoading = getOperatorRegionsIsLoadingSelector(state, `${channelId}/${operatorId}`);
    const hasFailed = getOperatorRegionsHasFailedSelector(state, `${channelId}/${operatorId}`);

    if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
      return apiRequest(
        d,
        GET_OPERATOR_REGIONS,
        urls.regions,
        { channel: channelId, opId: operatorId },
        `${channelId}/${operatorId}`,
      );
    }
  });
};

export const getRegions = (dispatch, channelId, force = false) => {
  dispatch((d, getState) => {
    const state = getState();
    const isLoaded = getRegionsIsLoadedSelector(state, channelId);
    const isLoading = getRegionsIsLoadingSelector(state, channelId);
    const hasFailed = getRegionsHasFailedSelector(state, channelId);

    if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
      return apiRequest(d, GET_REGIONS, urls.regions, { channel: channelId }, channelId);
    }
  });
};
