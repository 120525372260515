import { SUCCESS } from '../../constants';
import { GET_CACHED_AVAIL_DATA } from './action-types';

const initialState = {
  minPrices: {},
  minParkPrices: {},
  months: [],
  dates: [],
  ready: false,
  latedeals: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CACHED_AVAIL_DATA + SUCCESS:
      return {
        ...state,
        minPrices: action.payload.response.data.minprices,
        minParkPrices: action.payload.response.data.minparkprices,
        months: action.payload.response.data.months,
        dates: action.payload.response.data.dates,
        latedeals: action.payload.response.data.latedeals,
        ready: true,
      };
    default:
      return state;
  }
};

export default reducer;
