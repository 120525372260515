import { GET_CACHED_AVAIL_DATA } from './action-types';
import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { urls } from '../../../../config';

import { isReadySelector, isFetchingSelector, hasErrorSelector } from './selectors';

export const getCachedAvailData = (dispatch, force) => {
  dispatch((d, getState) => {
    const state = getState();
    const _isReady = isReadySelector(state);
    const _isFetching = isFetchingSelector(state);
    const _hasError = hasErrorSelector(state);

    if (checkRequestNeeded(true, _isFetching, _isReady, _hasError, force)) {
      apiRequest(d, GET_CACHED_AVAIL_DATA, urls.cachedAvail);
    }
  });
};
