import PropTypes from 'prop-types';

import RedEye from './RedEye';

export const RedEyeUserRegistrationTag = ({ details }) => (
  <RedEye
    params={{
      nourl: 'new-user-registration',
      rg_confirm: 'in-checkout',
      title: details.title,
      firstname: details.firstName,
      lastname: details.lastName,
      email: details.email,
      emailpermit: undefined,
      newsletter: undefined,
      postcode: details.postCode,
      phone: details.tel,
    }}
  />
);

RedEyeUserRegistrationTag.propTypes = {
  details: PropTypes.object,
};

export default RedEyeUserRegistrationTag;
