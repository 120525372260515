import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COUNTRY_OBJECT } from '../../../../Constants';
import { useLocalFormState } from '../../../../hooks/useLocalFormState';
import { createUser } from '../../../../redux/user/actions';
import {
  createUserErrorSelector,
  createUserFailedSelector,
  createUserPendingSelector,
} from '../../../../redux/user/selectors';
import { validateFields } from './CreateUserForm';

export const useCreateUserFormActions = () => {
  const dispatch = useDispatch();
  return {
    createUser: (data) => {
      return createUser(dispatch, data);
    },
  };
};

export const useCreateUserFormState = () => ({
  ...useLocalFormState(validateFields, DEFAULT_COUNTRY_OBJECT),
  createUserError: useSelector(createUserErrorSelector),
  createUserFailed: useSelector(createUserFailedSelector),
  createUserPending: useSelector(createUserPendingSelector),
});

export default useCreateUserFormState;
