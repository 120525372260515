import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../redux/user/actions';
import { loggedInSelector, loggingInSelector, loginErrorSelector, loginFailedSelector } from '../../../../redux/user/selectors';
import { validateFields } from './LoginForm';
import { useLocalFormState } from '../../../../hooks/useLocalFormState';

export const useLoginFormActions = () => {
  const dispatch = useDispatch();
  return {
    login: (email, passwd) => {
      return login(dispatch, email, passwd);
    },
  };
};

export const useLoginFormState = () => ({
  ...useLocalFormState(validateFields),
  loggedIn: useSelector(loggedInSelector),
  loggingIn: useSelector(loggingInSelector),
  loginError: useSelector(loginErrorSelector),
  loginFailed: useSelector(loginFailedSelector),
});

export default useLoginFormState;
