import PropTypes from 'prop-types';
import { Button, Col, Form } from 'react-bootstrap';

import ContactPreferencesInputs from '../ContactPreferencesForm/ContactPreferencesInputs';
import RequestErrors from '../../../RequestErrors';
import SelectInput from '../../../FormElements/SelectInput';
import TextInput from '../../../FormElements/TextInput';
import countries from '../../../../Data/Countries.json';

import titles from '../../../../Data/Titles.json';

import t from '../../../../../text';
import { dateValidator, email, password, postcode, required, telephone } from '../../../../utils/validation';
import { validateFields as validateContactPrefsFields } from '../ContactPreferencesForm/ContactPreferencesForm';
import { DOBInput } from '../../../FormElements/DOBInput';

export const validateFields = (values = {}) => {
  const errors = {};
  if (!required(values.title)) {
    errors.title = t('forms.forms.noValueSelected');
  }
  if (!required(values.firstName)) {
    errors.firstName = t('forms.user.blankFirstName');
  }
  if (!required(values.lastName)) {
    errors.lastName = t('forms.user.blankSurname');
  }
  if (!required(values.email) || !email(values.email)) {
    errors.email = t('forms.user.invalidEmail');
  }
  if (!required(values.email)) {
    errors.email = t('forms.user.blankEmail');
  }
  if (!required(values.passwd) || !password(values.passwd)) {
    errors.passwd = t('forms.user.invalidPassword');
  }
  if (!required(values.passwd)) {
    errors.passwd = t('forms.user.blankPassword');
  }
  if (!required(values.tel) || !telephone(values.tel)) {
    errors.tel = t('forms.user.invalidTelephone');
  }
  if (!required(values.dob) || !dateValidator(values.dob)) {
    errors.dob = t('forms.user.blankDob');
  }
  if (!required(values.addrLn1)) {
    errors.addrLn1 = t('forms.address.blankAddress');
  }
  if (!required(values.town)) {
    errors.town = t('forms.address.blankTown');
  }
  if (!required(values.postCode) || !postcode(values.postCode)) {
    errors.postCode = t('forms.address.invalidPostcode');
  }
  if (!required(values.county)) {
    errors.county = t('forms.address.blankCounty');
  }
  if (!required(values.country)) {
    errors.country = t('forms.forms.noValueSelected');
  }

  // Validate contact prefs fields
  Object.assign(errors, validateContactPrefsFields(values));

  return errors;
};

export const isValid = (values = {}) => !Object.keys(validateFields(values)).length;

export const CreateUserForm = ({ onChange, onSubmit, onBack, failure, busy = false, errors = {}, values = {} }) => {
  const handleDobChange = (field, value = '') => {
    const dobParts = value.split('/');
    onChange({
      [field]: value,
      dobDay: dobParts[0],
      dobMnth: dobParts[1],
      dobYr: dobParts[2],
    });
  };

  return (
    <Form name="detailsForm" noValidate>
      <Form.Row>
        <SelectInput
          groupClassName="col-12 col-sm-2"
          name="title"
          value={values.title || ''}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.title}
          validationMessage={errors.title}
          attrs={{ disabled: busy }}
        >
          <option value="" disabled>
            {t('forms.user.title')}
          </option>
          {Object.keys(titles).map((key) => (
            <option key={key} value={key}>
              {titles[key]}
            </option>
          ))}
        </SelectInput>

        <TextInput
          groupClassName="col-12 col-sm-5"
          placeholder={t('forms.user.firstName')}
          name="firstName"
          value={values.firstName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.firstName}
          validationMessage={errors.firstName}
          attrs={{ disabled: busy }}
        />

        <TextInput
          groupClassName="col-12 col-sm-5"
          placeholder={t('forms.user.surname')}
          name="lastName"
          value={values.lastName}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.lastName}
          validationMessage={errors.lastName}
          attrs={{ disabled: busy }}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.user.telephone')}
          name="tel"
          type="tel"
          value={values.tel}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.tel}
          validationMessage={errors.tel}
          attrs={{ disabled: busy }}
        />

        <DOBInput
          groupClassName="col-12 col-sm-6"
          value={values.dob}
          onChange={handleDobChange}
          invalid={!!errors.dob}
          validationMessage={errors.dob}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.user.emailAddress')}
          name="email"
          type="email"
          value={values.email}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.email}
          validationMessage={errors.email}
          attrs={{ disabled: busy }}
        />

        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.user.password')}
          name="passwd"
          type="password"
          value={values.passwd}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.passwd}
          validationMessage={errors.passwd}
          attrs={{ disabled: busy }}
        />
      </Form.Row>

      <h4>Your Address</h4>
      <Form.Row>
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.address.address')}
          name="addrLn1"
          value={values.addrLn1}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.addrLn1}
          validationMessage={errors.addrLn1}
          attrs={{ disabled: busy }}
        />
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.address.town')}
          name="town"
          value={values.town}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.town}
          validationMessage={errors.town}
          attrs={{ disabled: busy }}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.address.county')}
          name="county"
          value={values.county}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.county}
          validationMessage={errors.county}
          attrs={{ disabled: busy }}
        />
        <SelectInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.address.country')}
          name="country"
          value={values.country || ''}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.country}
          validationMessage={errors.country}
          attrs={{ disabled: busy }}
        >
          <option value="" disabled>
            {t('forms.address.selectCountry')}
          </option>
          {Object.keys(countries).map((key) => (
            <option key={key} value={key}>
              {countries[key].label}
            </option>
          ))}
        </SelectInput>
      </Form.Row>
      <Form.Row>
        <TextInput
          groupClassName="col-12 col-sm-6"
          placeholder={t('forms.address.postcode')}
          name="postCode"
          value={values.postCode}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          attrs={{ maxLength: 8, disabled: busy }}
          invalid={!!errors.postCode}
          validationMessage={errors.postCode}
        />
      </Form.Row>

      <ContactPreferencesInputs onChange={onChange} values={values} errors={errors} />

      {(onBack || onSubmit) && (
        <Form.Row noGutters>
          {onBack && (
            <Col xs={'auto'}>
              <Button variant="outline-primary" onClick={onBack} disabled={busy}>
                {t('buttons.back')}
              </Button>
            </Col>
          )}
          {onSubmit && (
            <Col>
              <Button block type="submit" onClick={onSubmit} disabled={busy}>
                {busy ? t('global.pleaseWait') : t('buttons.register')}
              </Button>
            </Col>
          )}
        </Form.Row>
      )}

      {failure && <RequestErrors requestError={failure} />}
    </Form>
  );
};

CreateUserForm.propTypes = {
  busy: PropTypes.bool,
  errors: PropTypes.object,
  failure: PropTypes.object,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
};

export default CreateUserForm;
