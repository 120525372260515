import PropTypes from 'prop-types';
import { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import t from '../../../text';
import { useIsIOS } from '../../hooks/useIsIOS';
import { useSubmitAndCloseDropdown } from '../../hooks/useSubmitAndCloseDropdown';
import { channelsSortedSelector } from '../../redux/data/channels/selectors';
import { selectChannel } from '../../redux/search/form/actions';
import { searchFormChannelSelector } from '../../redux/search/form/selectors';

const WHITE_LIST = ['ukcaravan', 'eurocaravan', 'uktouring', 'uklodge', 'boats', 'ukcottages'];

export const ChannelSelect = ({ textStyle = 'white' }) => {
  const sortedChannels = useSelector(channelsSortedSelector);

  const { submitAndCloseDropdown } = useSubmitAndCloseDropdown();

  const { isIOS } = useIsIOS();

  const [showChannelSelect, setShowChannelSelect] = useState(false);
  const handleChannelToggle = () => setShowChannelSelect(!showChannelSelect);
  const handleChannelSelect = (event) => {
    const channelChosen = event.target.value;
    submitAndCloseDropdown(channelChosen, selectChannel, setShowChannelSelect);
  };

  const currentChannel = useSelector(searchFormChannelSelector);
  const channelName = currentChannel?.name;
  const channelCode = currentChannel?.code;

  return (
    <Form.Group controlId={'searchbox-channel'}>
      <Form.Label className={`text-${textStyle}`}>
        <span>{t('search.holidayType')}</span>
      </Form.Label>
      {isIOS ? (
        <Form.Control as="select" className="p-0 toggle-popup" tabIndex="1" onChange={handleChannelSelect}>
          {sortedChannels.map((channel) => (
            <option
              key={channel.code}
              value={channel.code}
              className="py-2 pl-3"
              disabled={WHITE_LIST.length && !WHITE_LIST.includes(channel.code)}
              selected={channelCode === channel.code}
            >
              {channel.name}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Dropdown show={showChannelSelect} onToggle={handleChannelToggle}>
          <Dropdown.Toggle as="button" type="button" className="toggle-popup is-dropdown" role="button" tabIndex="0">
            <span>{channelName}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="a-popup p-0 pt-3" tabIndex="-1">
            {sortedChannels
              .filter((channel) => WHITE_LIST.includes(channel.code))
              .map((channel) => (
                <Form.Check
                  key={channel.code}
                  id={channel.code}
                  type="radio"
                  label={channel.name}
                  name="channelSelections"
                  value={channel.code}
                  onChange={handleChannelSelect}
                  disabled={WHITE_LIST.length && !WHITE_LIST.includes(channel.code)}
                  className="bf-radio"
                  checked={channelCode === channel.code}
                />
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Form.Group>
  );
};

ChannelSelect.propTypes = {
  textStyle: PropTypes.string,
};
