import { connect } from 'react-redux';
import Home from './Home';
import { contentSelector } from '../../redux/homepage/selectors';

const mapStateToProps = (state) => ({
  content: contentSelector(state),
});

const mapDispatchToProps = () => ({});

export const HomeConnected = connect(mapStateToProps, mapDispatchToProps)(Home);
export default HomeConnected;
