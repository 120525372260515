import { SELECT_CHANNEL, SELECT_REGION, SELECT_TOWN_ID } from './action-types';

const initialState = {
  channelId: undefined,
  regionId: undefined,
  townId: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CHANNEL:
      return {
        ...state,
        channelId: action.payload,
      };
    case SELECT_REGION:
      return {
        ...state,
        regionId: action.payload,
      };
    case SELECT_TOWN_ID:
      return {
        ...state,
        townId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
