import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { loggedInSelector } from '../../redux/user/selectors';

import Login from './Login';

export const LoginModal = (props) => {
  const _loggedIn = useSelector(loggedInSelector);

  useEffect(() => {
    if (props.show && _loggedIn && props.onClose) props.onClose();
  }, [_loggedIn, props]);

  return (
    <Modal show={props.show} onHide={props.onClose} centered dialogClassName="login-modal">
      <Modal.Header closeButton className="bg-lighter">
        <Modal.Title>
          <strong>Are you a member?</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Login redirectOnLogin={props.redirectOnLogin} handleClose={props.onClose} />
      </Modal.Body>
    </Modal>
  );
};

LoginModal.propTypes = {
  onClose: PropTypes.func,
  redirectOnLogin: PropTypes.bool,
  show: PropTypes.bool,
};

export default LoginModal;
