import { GET_CACHED_AVAIL_DATA } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const minPricesSelector = (state) => state.data.cachedAvail.minPrices;
export const minParkPricesSelector = (state) => state.data.cachedAvail.minParkPrices;
export const minPricesMonthsSelector = (state) => state.data.cachedAvail.months.sort();
export const minPricesDatesSelector = (state) => state.data.cachedAvail.dates;
export const lateDealsSelector = (state) => state.data.cachedAvail.latedeals;

export const [isFetchingSelector, hasErrorSelector, errorDataSelector] = getRequestStatus(GET_CACHED_AVAIL_DATA);
export const isReadySelector = (state) => state.data.cachedAvail.ready;
