import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

export const RequestErrors = ({ requestError, showCodes, classes = 'text-danger mt-2 mb-0 list-unstyled' }) => (
  <Row noGutters>
    <Col>
      {requestError && !requestError.errors && (
        <p className={classes}>Network error. Please check your internet connection and try again.</p>
      )}
      {requestError && Array.isArray(requestError.errors) && (
        <ul className={classes}>
          {requestError.errors.map((error) => (
            <li key={error.code}>
              {error.message}
              {showCodes && ` (code ${error.code})`}
            </li>
          ))}
        </ul>
      )}
    </Col>
  </Row>
);

RequestErrors.propTypes = {
  requestError: PropTypes.object, // Get this by using getError selector in redux/requests/selectors.js
  classes: PropTypes.string,
  showCodes: PropTypes.bool,
};

export default RequestErrors;
