import { MAX_VALUE_ON_PRICE_RANGE } from '../../Constants';
import { RESET_B_FILTERS, RESET_N_FILTERS, SET_STATE } from './action-types';

export const N_FILTERS_STATE_KEY = 'nFiltersStateArray';
export const B_FILTERS_STATE_KEY = 'bFiltersStateArray';

const defaultState = {
  bFiltersStateArray: [],
  nFiltersStateArray: [],
  rangeSliderMin: 0,
  rangeSliderMax: MAX_VALUE_ON_PRICE_RANGE,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.data,
      };
    case RESET_N_FILTERS:
      return {
        ...state,
        nFiltersStateArray: [],
      };
    case RESET_B_FILTERS:
      return {
        ...state,
        bFiltersStateArray: [],
      };
    default:
      return state;
  }
};

export default reducer;
