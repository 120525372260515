import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  searchFormChannelIdSelector,
  searchFormRegionIdSelector,
  searchFormRequestParamsSelector,
} from '../redux/search/form/selectors';
import { useLocation } from 'react-router-dom';

const channelNewLinkMap = {
  ukcaravan: 'https://www.haven.com/offers?wacampaign=breakfree&utm_medium=promo&utm_source=breakfree',
};

export const useUpdateRoute = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const requestParams = useSelector(searchFormRequestParamsSelector);
  const selectedRegionId = useSelector(searchFormRegionIdSelector);
  const selectedChannel = useSelector(searchFormChannelIdSelector);

  const updateRoute = () => {
    const searchQuery = Object.keys(requestParams)
      .filter((key) => typeof requestParams[key] !== 'undefined' && key !== 'partysize')
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(requestParams[key])}`)
      .join('&');

    // Determines if we should open a new tab and what to replace the current one with
    const newTabUrl = channelNewLinkMap[selectedChannel];

    const basePath = '/results';
    const allResultsPath = `${basePath}/all-regions`;
    const targetPath = selectedRegionId === 'all-regions' ? allResultsPath : basePath;

    // Only fires on homepage
    // eslint-disable-next-line no-undef
    if (newTabUrl && pathname === '/' && !process.env.NODE_ENV === 'development') {
      // Open target path in a new tab and replace the current tab URL
      window.open(`${targetPath}?${searchQuery}`, '_blank');
      window.location.replace(newTabUrl);
    } else {
      history.push({ pathname: targetPath, search: searchQuery });
    }
  };

  return { updateRoute };
};
