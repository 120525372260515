export const getRegionById = (regions, regionId) => {
  return regions[regionId];
};

export const getRegionsByChannelId = (regions, channelRegionIds, channelId) => {
  return channelRegionIds[channelId]?.map((regionId) => regions[regionId]);
};

export const getOperatorRegionsById = (regions, operatorChannelRegionIds, channelId, operatorId) => {
  return operatorChannelRegionIds[operatorId]?.[channelId]?.map((regionId) => regions[regionId]);
};
