import { useState, useEffect, useCallback } from 'react';

const useLocalStorage = (key, initialValue, expireInMonths = 6) => {
  // Function to calculate the expiration time
  const calculateExpiryTime = useCallback(() => {
    const now = new Date();
    now.setMonth(now.getMonth() + expireInMonths);
    return now.getTime();
  }, [expireInMonths]);

  // Function to get the initial value from local storage or use the provided initial value
  const getStoredValue = () => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        const parsedItem = JSON.parse(item);
        if (parsedItem.expiry && new Date().getTime() > parsedItem.expiry) {
          window.localStorage.removeItem(key);
          return initialValue;
        }
        return parsedItem.value;
      }
      return initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  };

  // State to store the current value
  const [storedValue, setStoredValue] = useState(getStoredValue);

  // Effect to update local storage whenever the state changes
  useEffect(() => {
    try {
      const expiryTime = calculateExpiryTime();
      const valueToStore = {
        value: storedValue,
        expiry: expiryTime,
      };
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  }, [calculateExpiryTime, key, storedValue]);

  // Function to update the state and local storage
  const setValue = (value) => {
    setStoredValue((prevValue) => (typeof value === 'function' ? value(prevValue) : value));
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
