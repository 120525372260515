import { useState } from 'react';
import { Form } from 'react-bootstrap';
import t from '../../../text';

export const DOBInput = ({ invalid, value, validationMessage, onChange, attrs, groupClassName = '', name = 'dob' }) => {
  const [dob, setDob] = useState(value);

  const handleInputChange = (e) => {
    const { value } = e.target;
    let cursorPosition = e.target.selectionStart;

    let formattedValue = value
      .replace(/[^0-9]/g, '') // Allow only numbers
      .replace(/^(\d{2})(\d)/, '$1/$2') // Add a slash after the day (dd)
      .replace(/(\d{2})\/(\d{2})(\d)/, '$1/$2/$3') // Add a slash after the month (mm)
      .replace(/(\/\d{4})\d*/, '$1');

    const insertSlashesAfterCharacters = [2, 5];
    const shouldInsertSlash = insertSlashesAfterCharacters.includes(formattedValue.length);
    switch (e.nativeEvent.inputType) {
      case 'insertText': // User just added text
        if (shouldInsertSlash) {
          formattedValue += '/';
        } else if (formattedValue.length === 4 || formattedValue.length === 7) {
          cursorPosition++;
        }
        break;
      case 'deleteContentBackward': // User just pressed backspace
        if (shouldInsertSlash) {
          formattedValue += '/';
          cursorPosition--;
        }
        break;
      case 'deleteContentForward': // User just pressed delete
        if (insertSlashesAfterCharacters.includes(cursorPosition)) {
          cursorPosition++;
          if (shouldInsertSlash) {
            formattedValue += '/';
          }
        } else if (shouldInsertSlash) {
          formattedValue += '/';
          if (cursorPosition > 0) cursorPosition--;
        }
        break;
      default:
        break;
    }

    setDob(formattedValue);

    if (formattedValue.length === 10) {
      onChange(name, formattedValue);
    }

    setTimeout(() => {
      // Restore the cursor position
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <Form.Group controlId={name} className={groupClassName} style={{ position: 'relative' }}>
      <Form.Control
        className={`${invalid ? 'is-invalid' : ''}`}
        type="tel"
        placeholder={t('forms.party.dob')}
        value={dob}
        onChange={handleInputChange}
        maxLength={10}
        {...attrs}
      />
      {invalid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
    </Form.Group>
  );
};
