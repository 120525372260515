import { RESET_B_FILTERS, RESET_N_FILTERS, SET_STATE } from './action-types';

export const setState = (dispatch, key, data) => {
  dispatch({
    type: SET_STATE,
    payload: { key, data },
  });
};

export const resetNFilters = (dispatch) => {
  dispatch({ type: RESET_N_FILTERS });
};

export const resetBFilters = (dispatch) => {
  dispatch({ type: RESET_B_FILTERS });
};
