import { useMemo, useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import t from '../../../text';
import { SearchBox } from '../SearchBox/SearchBox';
import { usePageWidth } from '../../hooks/usePageWidth';
import { BREAKPOINTS } from '../../Constants';

const HEADER_HEIGHT = 80;
const SCROLL_PADDING = 15;

function Hero({
  heading,
  headingImage,
  subheading,
  mobileBackground = '',
  desktopBackground = '',
  largeDesktopBackground = '',
}) {
  const { width } = usePageWidth();
  const belowMedium = width < BREAKPOINTS.md;
  const aboveMedium = width >= BREAKPOINTS.md;
  
  const isMediumScreen = aboveMedium && width < 992;
  const aboveLarge = width > 1200;

  const buttonRef = useRef(null);

  const handleScrollToSearch = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Bootstrap's medium breakpoint starts at 768px and goes up to 991.98px

  const backgroundContent = useMemo(() => {
    if (belowMedium && mobileBackground) {
      return `url(${mobileBackground})`;
    }
    if (aboveLarge && largeDesktopBackground) return `url(${largeDesktopBackground})`;
    return desktopBackground ? `url(${desktopBackground})` : '';
  }, [belowMedium, mobileBackground, aboveLarge, largeDesktopBackground, desktopBackground]);

  return (
    <Container
      fluid
      className="masthead d-flex flex-column px-0"
      style={{ backgroundImage: isMediumScreen ? '' : backgroundContent }}
    >
      {belowMedium && mobileBackground.includes('mp4') && <Video src={mobileBackground} />}
      {aboveMedium && desktopBackground.includes('mp4') && <Video src={desktopBackground} />}
      <Row className="flex-grow-1 mx-0" style={{ backgroundImage: isMediumScreen ? backgroundContent : '' }}>
        <Col className="d-flex flex-column align-items-center text-center text-white mt-4 mt-md-3">
          {headingImage ? (
            <img src={headingImage} className="heading-img" alt={heading} width="90%" />
          ) : (
            <h1 className="text-white mt-2" data-mh-lead>
              {heading}
            </h1>
          )}
          <p className="text-white mb-0 mx-auto" data-mh-sub>
            {subheading}
          </p>
          <Button
            className="d-md-none mt-auto mb-4 mx-auto px-5"
            style={{ scrollMarginTop: HEADER_HEIGHT + SCROLL_PADDING }}
            variant="secondary"
            ref={buttonRef}
            onClick={handleScrollToSearch}
          >
            {t('search.searchNow')}
          </Button>
        </Col>
      </Row>
      <Row className="d-none d-md-block align-self-end bg-primary w-100 mx-0">
        <Col className="py-4 text-white">
          <SearchBox isHeroPage={isMediumScreen} includeEmail isHomePage />
        </Col>
      </Row>
    </Container>
  );
}

const Video = ({ src }) => (
  <video muted autoPlay loop playsInline>
    <source src={src} type="video/mp4" />
  </video>
);

export default Hero;
