import { getRequestStatus } from '../requests/selectors';
import {
  CHANGE_PASSWORD,
  CREATE_USER,
  GET_BOOKINGS,
  LOGIN,
  LOGOUT,
  REQUEST_PASSWORD_RESET,
  UPDATE_USER,
} from './action-types';

export const loggedInSelector = (state) => state.user.loggedIn;
export const selectedContactPreferencesInBookingSelector = (state) => state.user.selectedContactPreferencesInBooking;
export const redeyeUrlSelector = (state) => state.user.details.redeyeUrl;
export const titleSelector = (state) => state.user.details.title;
export const firstNameSelector = (state) => state.user.details.firstName;
export const lastNameSelector = (state) => state.user.details.lastName;
export const emailSelector = (state) => state.user.details.email;
export const addrLn1Selector = (state) => state.user.details.addrLn1;
export const addrLn2Selector = (state) => state.user.details.addrLn2;
export const addrLn3Selector = (state) => state.user.details.addrLn3;
export const townSelector = (state) => state.user.details.town;
export const citySelector = (state) => state.user.details.city;
export const postCodeSelector = (state) => state.user.details.postCode;
export const telSelector = (state) => state.user.details.tel;
export const emailOptSelector = (state) => state.user.details.emailOpt;
export const smsOptSelector = (state) => state.user.details.smsOpt;
export const postOptSelector = (state) => state.user.details.postOpt;
export const thirdOptSelector = (state) => state.user.details.thirdOpt;

export const bookingsSelector = (state) => state.user.bookings;
export const detailsSelector = (state) => state.user.details;

export const [changePasswordPendingSelector, changePasswordFailedSelector, changePasswordErrorSelector] =
  getRequestStatus(CHANGE_PASSWORD);

export const [createUserPendingSelector, createUserFailedSelector, createUserErrorSelector] =
  getRequestStatus(CREATE_USER);

export const getBookingsLoadedSelector = (state) => typeof bookingsSelector(state) !== 'undefined';
export const [getBookingsPendingSelector, getBookingsFailedSelector, getBookingsErrorSelector] =
  getRequestStatus(GET_BOOKINGS);

export const [loggingInSelector, loginFailedSelector, loginErrorSelector] = getRequestStatus(LOGIN);

export const [loggingOutSelector, logoutFailedSelector, logoutErrorSelector] = getRequestStatus(LOGOUT);

export const [
  requestPasswordResetPendingSelector,
  requestPasswordResetFailedSelector,
  requestPasswordResetErrorSelector,
] = getRequestStatus(REQUEST_PASSWORD_RESET);

export const [updateUserPendingSelector, updateUserFailedSelector, updateUserErrorSelector] =
  getRequestStatus(UPDATE_USER);
