import { GET_OPERATOR_REGIONS, GET_REGIONS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';
import { slugify } from '../../../utils/string';
import { createSelector } from 'reselect';

export const regionsSelector = (state) => state.data.regions.regions;
export const channelRegionIdsSelector = (state) => state.data.regions.channelRegionIds;
export const operatorChannelRegionIdsSelector = (state) => state.data.regions.operatorChannelRegionIds;

// Utils to be used by other selectors
export const [getRegionsIsLoadingSelector, getRegionsHasFailedSelector, getRegionsErrorSelector] =
  getRequestStatus(GET_REGIONS);
export const getRegionsIsLoadedSelector = (state, channelId) => !!channelRegionIdsSelector(state)[channelId];

export const [
  getOperatorRegionsIsLoadingSelector,
  getOperatorRegionsHasFailedSelector,
  getOperatorRegionsErrorSelector,
] = getRequestStatus(GET_OPERATOR_REGIONS);

export const getOperatorRegionsIsLoadedSelector = (state, channelId, operatorId) => {
  return !!operatorChannelRegionIdsSelector(state)[operatorId]?.[channelId];
};

// This is used for travel guides
// We get the region name from the url, and that can have dashes between words
// The only way to unslugify it is to check what it was originally via this mapping function
export const getUnslugifiedNamesSelector = createSelector(regionsSelector, (regions) => {
  const namesObject = {};

  Object.values(regions).forEach((value) => {
    const name = value.name;
    const slugifyName = slugify(value.name);
    namesObject[slugifyName] = name;
  });

  return namesObject;
});
