import { useDispatch } from 'react-redux';

// Runs one dispatch function straight away and another function after 650ms
export const useSubmitAndCloseDropdown = () => {
  const dispatch = useDispatch();

  const submitAndCloseDropdown = (value, func, selectFunc) => {
    func(dispatch, value);
    setTimeout(() => {
      selectFunc(false);
    }, 650);
  };

  return { submitAndCloseDropdown };
};
