import { GET_PARKS } from './action-types';
import { getRequestStatus } from '../../requests/selectors';

export const parks = (state) => state.data.parks.parks;
export const townsSelector = (state) => state.data.parks.townList;
export const regionParkIds = (state) => state.data.parks.regionParkIds;

// Utils to be used by other selectors
export const [getIsLoading, getHasFailed, getErrorData] = getRequestStatus(GET_PARKS);
export const getIsLoaded = (state, regionId) => !!regionParkIds(state)[regionId];

export const getSpecificParks = (state, idArr) => {
  const parks = [];
  idArr.forEach((id) => {
    const park = state.data.parks.parks[id];
    parks.push(park);
  });

  return parks;
};
