import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';

import InputRadioButton from './InputRadioButton';

export const InputRadioGroup = ({
  invalid,
  text,
  noSpacing,
  onChange,
  validationMessage,
  className = '',
  groupClassName = '',
  options = [],
  values = {},
}) => (
  <Form.Row className={`inputRadioGroup ${invalid ? 'invalid' : ''} ${className}`}>
    <Form.Group as={Col} className={groupClassName}>
      {text && <p className="mb-2">{text}</p>}

      {options.map((option, i) => {
        const checked = values[name] === option.value;
        const isLast = i !== options.length - 1;

        return (
          <div
            className={`d-inline-block ${!isLast || noSpacing ? '' : 'mb-2 mr-2 pr-1'}`}
            key={`${name}-${option.value}`}
          >
            <InputRadioButton
              name={name}
              value={option.value}
              label={option.label}
              checked={checked}
              onChange={onChange}
            />
          </div>
        );
      })}

      {validationMessage && <div className="invalid-feedback d-block">{validationMessage}</div>}
    </Form.Group>
  </Form.Row>
);

InputRadioGroup.propTypes = {
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  invalid: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array, // [{label: '', value ''}, ...]
  text: PropTypes.string,
  validationMessage: PropTypes.string,
  values: PropTypes.object,
};

export default InputRadioGroup;
