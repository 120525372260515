import { SUCCESS } from '../constants';
import { GET_INIT_DATA } from '../init/action-types';
import { SET_OFFERS } from './action-types';

const initialState = {
  offersData: [],
  selectedOffers: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_DATA + SUCCESS:
      return {
        ...state,
        offersData: action.payload.response.data.data.offers || [],
      };
    case SET_OFFERS:
      return {
        ...state,
        selectedOffers: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
