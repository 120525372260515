import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config, urls } from '../../../config';
import { getNav } from '../../redux/header/actions';
import { appInit, getInitData } from '../../redux/init/actions';
import { hasError, isFetching, isReady } from '../../redux/init/selectors';

import t from '../../../text';
import WithLoadingStates from './WithLoadingStates';

import { apiRequest } from '../../redux/apiRequest';
import { getCachedAvailData } from '../../redux/data/cachedAvail/actions';
import { GET_INIT_DATA } from '../../redux/init/action-types';

export const WithInit = (props) => {
  const appIsReady = useSelector(isReady);
  const appIsFetching = useSelector(isFetching);
  const appHasError = useSelector(hasError);
  const dispatch = useDispatch();
  const [firstRun, setFirstRun] = useState(true);

  if (firstRun) {
    setFirstRun(false);
    appInit(dispatch); // Super important - Causes request statuses to be reset (amongst other things)

    getInitData(dispatch);
    getNav(dispatch);
    getCachedAvailData(dispatch);

    window.addEventListener('storage', (ev) => {
      if (ev.key === 'hasAccountData') {
        dispatch((d) => {
          apiRequest(d, GET_INIT_DATA, urls.init, { siteCode: config.siteCode });
        });
      }
    });
  }

  return (
    <WithLoadingStates
      isFetching={appIsFetching}
      hasErrorFetching={appHasError}
      loadingErrorMessage={t('init.errorLoading')}
      onRetry={() => getInitData(dispatch, true)}
      useContainer={true}
    >
      {appIsReady ? props.children : null}
    </WithLoadingStates>
  );
};

export default WithInit;
