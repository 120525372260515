import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { loggedInSelector } from '../../redux/user/selectors';

import { CreateUserFormView } from './views/CreateUserFormView';
import LoggedInView from './views/LoggedInView';
import LoginFormView from './views/LoginFormView';
import PasswordResetFormView from './views/PasswordResetFormView';

function Login({ handleClose, redirectOnLogin = true}) {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [viewMode, setViewMode] = useState(query.has('forgot-password') ? 'reset' : 'login'); // One of login|register|loggedin|reset
  const _loggedIn = useSelector(loggedInSelector);

  const redirectToPage = () => {
    if (_loggedIn && redirectOnLogin) {
      const params = new URLSearchParams(history.location.search);
      const redirect = params.get('redirect');
      history.replace(redirect || '/my-account');
    }
  };

  useEffect(redirectToPage, [redirectOnLogin, history, _loggedIn]);

  return (
    <>
      {_loggedIn ? (
        <LoggedInView />
      ) : (
        <>
          {viewMode === 'login' && (
            <LoginFormView onViewRegister={() => setViewMode('register')} onViewReset={() => setViewMode('reset')} />
          )}
          {viewMode === 'register' && <CreateUserFormView onBack={() => setViewMode('login')} />}
          {viewMode === 'reset' && (
            <PasswordResetFormView onBack={() => setViewMode('login')} handleClose={handleClose} />
          )}
        </>
      )}
    </>
  );
}

Login.propTypes = {
  redirectOnLogin: PropTypes.bool,
};

export default Login;
