import { useDispatch, useSelector } from 'react-redux';
import { cancelSearch, startSearch } from '../redux/search/form/actions';
import { searchFormParamsSelector, searchFormRequestParamsSelector } from '../redux/search/form/selectors';
import { cancelGetResults } from '../redux/search/results/actions';

export const useFetchSearchResults = () => {
  const dispatch = useDispatch();
  const params = useSelector(searchFormParamsSelector);
  const requestParams = useSelector(searchFormRequestParamsSelector);

  const fetchSearchResults = () => {
    cancelGetResults(); // Cancel last request to get search results
    cancelSearch(); // Cancel last request to start a search
    startSearch(dispatch, params, requestParams, undefined);
  };

  return { fetchSearchResults };
};
