import PropTypes from 'prop-types';
import { useState } from 'react';
import CreateUserForm from '../../Forms/User/CreateUserForm/CreateUserForm';
import { useCreateUserFormActions, useCreateUserFormState } from '../../Forms/User/CreateUserForm/useCreateUserForm';
import RedEyeUserRegistrationTag from '../../RedEye/RedEyeUserRegistrationTag';

export const CreateUserFormView = (props) => {
  const { values, onChange, validate, isValid, errors, createUserError, createUserPending } = useCreateUserFormState();
  const { createUser } = useCreateUserFormActions();
  const [firstRun, setFirstRun] = useState(true); // Used to hide error messages if just opened
  const [doTagging, setDoTagging] = useState(false);
  
  const handleRegister = (event) => {
    event.preventDefault();
    setFirstRun(false);
    validate();
    if (isValid()) {
      setDoTagging(true);
      fetch('/api/logout') // Fixes 'Already logged in'.
        .then(() => {
          createUser(values)
            .request.catch(() => {})
            .finally(() => {
              setDoTagging(false);
            });
        });
    }
  };

  return (
    <>
      <CreateUserForm
        busy={createUserPending}
        errors={firstRun ? undefined : errors}
        failure={firstRun ? undefined : createUserError}
        onBack={props.onBack}
        onChange={onChange}
        onSubmit={handleRegister}
        values={values}
      />
      {doTagging && <RedEyeUserRegistrationTag details={values} />}
    </>
  );
}

CreateUserFormView.propTypes = {
  onBack: PropTypes.func,
};
