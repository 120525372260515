export const getRequestReducerById = (state, actionType) => state.requests[actionType];

export const getError = (state, actionType, requestId = '@') => {
  const reducer = getRequestReducerById(state, actionType);
  return reducer ? reducer.errors[requestId] : undefined;
};

export const getHasError = (state, actionType, requestId = '@') => {
  const reducer = getRequestReducerById(state, actionType);
  return reducer ? !!reducer.errors[requestId] : false;
};

export const getIsBusy = (state, actionType) => {
  const reducer = getRequestReducerById(state, actionType);
  return !!reducer.fetching.length;
};

export const getIsFetching = (state, actionType, requestId = '@') => {
  const reducer = getRequestReducerById(state, actionType);
  return reducer ? reducer.fetching.includes(requestId) : false;
};

export const getRequestStatus = (actionType) => {
  return [
    (state, requestId) => getIsFetching(state, actionType, requestId),
    (state, requestId) => getHasError(state, actionType, requestId),
    (state, requestId) => getError(state, actionType, requestId),
  ];
};
