import { apiRequest, checkRequestNeeded } from '../../apiRequest';
import { GET_RESULTS } from './action-types';
import { hasError, isFetching, isSearching, isSearchRequestPending, isWaiting } from './selectors/data';

import { urls } from '../../../../config';

let resultsInterval;
let lastGetResultsRequest;

export const cancelGetResults = () => {
  window.clearTimeout(resultsInterval);
  if (lastGetResultsRequest) lastGetResultsRequest.cancel();
};

export const getResults = (dispatch, force) => {
  dispatch((d, getState) => {
    const state = getState();
    const _isReady = !isSearchRequestPending(state) && isSearching(state);
    const _isFetching = isFetching(state);
    const _hasError = hasError(state);
    const _isWaiting = isWaiting(state);

    const loadData = () => {
      cancelGetResults();
      lastGetResultsRequest = apiRequest(d, GET_RESULTS, urls.searchResults);
    };

    if (checkRequestNeeded(_isReady, _isFetching, false, _hasError, force)) {
      // Will keep making request every 2 seconds if we're waiting (search not complete)
      window.clearTimeout(resultsInterval);
      resultsInterval = setTimeout(loadData, _isWaiting ? 2000 : 500);
    }
  });
};
