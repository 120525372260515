/**
 * Retrieves the text string for the given key from the given text pack.
 * Text entries can either be strings or functions (to allow for string parameters).
 * Prints a warning to console and returns the key if the key isn't in the pack.
 * @param {string} key - The key you wish to retrieve the corresponding text for
 * @param {Object} textPack - And object full of key/value pairs for text keys and corresponding text strings
 * @param {Array} params - An array of parameters to send to a text string function
 */
export function getTranslation(key, textPack, params) {
  if (typeof textPack[key] === 'undefined') {
    console.warn(`No entry found for text key '${key}'`);
    return key;
  }
  if (typeof textPack[key] === 'function') {
    return textPack[key](...params);
  }
  return textPack[key];
}
