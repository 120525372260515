import { GET_EXTRAS } from './action-types';
import { SUCCESS } from '../../constants';

const initialState = {
  extras: [],
  showDiscountBox: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTRAS + SUCCESS:
      return {
        ...initialState,
        extras: action.payload.response.data.data.extras,
        showDiscountBox: action.payload.response.data.data.showDiscountBox,
      };
    default:
      return state;
  }
};

export default reducer;
