import PropTypes from 'prop-types';
import { Form, Col, Button } from 'react-bootstrap';
import TextInput from '../../../FormElements/TextInput';
import { required, email, password } from '../../../../utils/validation';
import RequestErrors from '../../../RequestErrors';
import t from '../../../../../text';

export const validateFields = (values) => {
  const errors = {};
  if (!required(values.email) || !email(values.email)) {
    errors.email = t('forms.user.invalidEmail');
  }
  if (!required(values.email)) {
    errors.email = t('forms.user.blankEmail');
  }
  if (!required(values.password) || !password(values.password)) {
    errors.password = t('forms.login.invalidPassword');
  }
  if (!required(values.password)) {
    errors.password = t('forms.login.blankPassword');
  }
  return errors;
};

export const LoginForm = ({
  isBookingPage,
  onLogin,
  onViewReset,
  failure,
  onViewRegister,
  onChange,
  busy = false,
  errors = {},
  values = {},
}) => (
  <Form name="loginForm" noValidate>
    <Form.Row>
      <Col md={isBookingPage ? '6' : '12'}>
        <TextInput
          name="email"
          type="email"
          placeholder={t('forms.user.emailAddress')}
          className="mb-3"
          value={values.email}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.email}
          validationMessage={errors.email}
          attrs={{ 'aria-label': t('forms.user.emailAddress'), disabled: busy, autoComplete: 'username' }}
        />
      </Col>
      <Col md={isBookingPage ? '6' : '12'}>
        <TextInput
          name="password"
          type="password"
          placeholder={t('forms.user.password')}
          className="mb-3"
          value={values.password}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          invalid={!!errors.password}
          validationMessage={errors.password}
          attrs={{ 'aria-label': t('forms.user.password'), disabled: busy, autoComplete: 'current-password' }}
        />
      </Col>
    </Form.Row>
    <Form.Row className="d-flex align-items-center no-gutters">
      <Col sm={12} md={6}>
        <Button type="submit" block onClick={onLogin} disabled={busy}>
          {busy ? t('global.pleaseWait') : t('buttons.login')}
        </Button>
      </Col>
      <Col md={isBookingPage ? '6' : 'auto'} sm={12}>
        <Button variant="link" className="pl-1 pr-0" onClick={onViewReset}>
          <small>{t('forms.login.forgotYourPassword')}</small>
        </Button>
      </Col>
    </Form.Row>

    {failure && <RequestErrors requestError={failure} />}

    {!isBookingPage && (
      <>
        <h5 className="mt-3 mb-3">{t('forms.login.notAMember')}</h5>
        <Button block variant="primary" onClick={onViewRegister}>
          {t('buttons.createAnAccount')}
        </Button>
      </>
    )}
  </Form>
);

LoginForm.propTypes = {
  busy: PropTypes.bool,
  errors: PropTypes.object,
  failure: PropTypes.object,
  onChange: PropTypes.func,
  onLogin: PropTypes.func,
  onViewRegister: PropTypes.func,
  onViewReset: PropTypes.func,
  values: PropTypes.object,
};

export default LoginForm;
