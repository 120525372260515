import { useEffect, useState } from 'react';
import { urls } from '../../../config';

export const useGetTravelGuideData = (regionName) => {
  const [ttdData, setTtdData] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    fetch(
      `${urls.cms}/travel-guides/?filter[meta_key]=region_name&filter[meta_value]=${encodeURIComponent(
        regionName,
      )}&_fields=acf`,
      { signal: controller.signal },
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          const wpData = data[0];
          setTtdData(wpData.acf['things-to-do']);
        } else {
          setTtdData([]);
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error('Could not fetch the content.', err);
        }
      });

    return () => controller.abort();
  }, [regionName]);

  return { ttdData };
};
