export const offersDataSelector = (state) => state.offers.offersData;

// We filter out selectedOffers that dont exist in the offersData here at the source
export const selectedOffersSelector = (state) => {
  const storedOffers = offersDataSelector(state);

  const selectedOffers = state.offers.selectedOffers;
  const filteredOfferNumbers = selectedOffers.filter(offer => 
    storedOffers.some(data => data.urn === offer)
  );
  return filteredOfferNumbers;
};

export const currentOffersDataSelector = (state) => {
  const storedOffers = offersDataSelector(state);
  const selectedOffers = selectedOffersSelector(state);
  return storedOffers.filter(({ urn }) => {
    return selectedOffers.includes(urn);
  });
};

// There are two conditions to showing offers UI and filtering by them
// 1. Does the current offer exist in the offers data set
// 2. Do any of the current results have the currently selected offer
export const shouldUseOffersSelector = (state, results = []) => {
  const storedOffers = offersDataSelector(state);
  const currentOffers = selectedOffersSelector(state);

  if (results?.length === 0) return false;

  const doesOfferExist = storedOffers?.some(({ urn }) => currentOffers.includes(urn));
  if (!doesOfferExist) return false;

  const hasMatchingOffers = results.some((result) => result.offers.every((offer) => currentOffers.includes(offer)));
  return hasMatchingOffers;
};
