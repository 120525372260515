import { GET_PARK_DETAILS } from './action-types';
import { SUCCESS } from '../../../constants';
import { processPark } from '../../parks/utils';

const initialState = {
  parkDetails: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARK_DETAILS + SUCCESS:
      return {
        ...state,
        parkDetails: {
          ...state.parkDetails,
          [action.payload.params.parkId]: processPark(action.payload.response.data.data.park),
        },
      };
    default:
      return state;
  }
};

export default reducer;
