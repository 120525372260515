import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import TextInput from '../../../FormElements/TextInput';
import { required, email } from '../../../../utils/validation';
import RequestErrors from '../../../RequestErrors';
import t from '../../../../../text';

export const validateFields = (values) => {
  const errors = {};
  if (!required(values.email) || !email(values.email)) {
    errors.email = t('forms.user.invalidEmail');
  }
  if (!required(values.email)) {
    errors.email = t('forms.user.blankEmail');
  }
  return errors;
};

export const PasswordResetForm = ({
  handleClose,
  loggedIn,
  onChange,
  format,
  onBack,
  failure,
  onSubmit,
  busy = false,
  errors = {},
  success = false,
  values = {},
}) => {
  const history = useHistory();

  useEffect(() => {
    if (success && handleClose) {
      handleClose();
    }
  }, [success, handleClose, history]);

  return (
    <Form name="passwordResetForm" noValidate>
      {!loggedIn && (
        <Form.Row className="d-flex align-items-center">
          <Col>
            <h5>Reset Your Password</h5>
            <p>Enter the email address associated with your account to receive a password reset email.</p>
          </Col>
        </Form.Row>
      )}
      <Form.Row className="d-flex align-items-center">
        <Col>
          <TextInput
            name="email"
            type="email"
            placeholder={t('forms.user.emailAddress')}
            className="mb-3"
            value={values.email}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            invalid={!!errors.email}
            validationMessage={errors.email}
            attrs={{ disabled: busy || loggedIn, autoComplete: 'username' }}
          />
        </Col>
      </Form.Row>

      <Form.Row className="d-flex align-items-center">
        {format !== 'noback' && !loggedIn && (
          <Col xs={'auto'}>
            <Button type="button" variant="outline-primary" onClick={onBack} disabled={busy}>
              {t('buttons.back')}
            </Button>
          </Col>
        )}

        <Col>
          <Button type="submit" block onClick={onSubmit} disabled={busy}>
            {busy ? t('global.pleaseWait') : t('buttons.resetPassword')}
          </Button>
        </Col>
      </Form.Row>

      {failure && <RequestErrors requestError={failure} />}
      {success && <p className="text-success mt-2 mb-0">{t('forms.resetPassword.success', loggedIn)}</p>}
      {success && <Redirect to="/change-password" />}
    </Form>
  );
};

PasswordResetForm.propTypes = {
  busy: PropTypes.bool,
  errors: PropTypes.object,
  failure: PropTypes.object,
  loggedIn: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  success: PropTypes.bool,
  values: PropTypes.object,
};

export default PasswordResetForm;
