import PropTypes from 'prop-types';

function getUrlWithParams(src, params) {
  const href = src.split('?')[0];
  const combinedParams = {
    ...Object.fromEntries(new URLSearchParams(src.split('?')[1] || '')),
    ...params,
  };

  // Remove anything that is null / undefined / empty string
  Object.keys(combinedParams).forEach((key) => {
    if (combinedParams[key] === null || combinedParams[key] === undefined || combinedParams[key] === '') {
      delete combinedParams[key];
    }
  });

  // Using encodeURIComponent here instead of URLSearchParams as URLSearchParams encodes things like spaces differently
  const encodedParamString = Object.keys(combinedParams)
    .map((k) => `${k}=${combinedParams[k]}`)
    .join('&')
    .replaceAll('%2F', '/');

  return `${href}?${encodedParamString}`;
}

const RedEye = ({ src = 'https://reporting.breakfreeholidays.co.uk/cgi-bin/rr/blank.gif', params = {} }) => (
  <img src={getUrlWithParams(src, params)} width="1" height="1" alt="" />
);

RedEye.propTypes = {
  src: PropTypes.string,
  params: PropTypes.object,
};

export default RedEye;
