import { GET_NAV } from './action-types';
import { apiRequest, checkRequestNeeded } from '../apiRequest';
import { urls } from '../../../config';

import { isFetching, hasError, navLoaded } from './selectors';

export const getNav = (dispatch, force) => {
  dispatch((d, getState) => {
    const state = getState();
    const isLoaded = navLoaded(state);
    const isLoading = isFetching(state);
    const hasFailed = hasError(state);

    if (checkRequestNeeded(true, isLoading, isLoaded, hasFailed, force)) {
      apiRequest(d, GET_NAV, urls.nav);
    }
  });
};
