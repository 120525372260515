import {
  SELECT_CHANNEL,
  SELECT_REGION,
  SELECT_DATE,
  SELECT_DURATION,
  SELECT_GUESTS_ADULTS,
  SELECT_GUESTS_CHILDREN,
  SELECT_GUESTS_UNDER5S,
  SELECT_PET_FRIENDLY,
  STORE_COTTAGE_DATES,
  RESET_ARRIVAL,
  SELECT_MONTH,
} from './action-types';

const initialState = {
  channelId: 'ukcaravan',
  regionId: undefined,
  arrivalDate: undefined,
  duration: 3,
  month: undefined,
  adults: 0,
  children: 0,
  under5s: 0,
  petFriendly: false,
  searchNeeded: false,
  partySizeModal: false,
  cottageDates: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CHANNEL:
      return {
        ...state,
        channelId: action.payload.channelId,
        regionId: action.payload.regionId,
      };
    case SELECT_REGION:
      return {
        ...state,
        regionId: action.payload,
      };
    case SELECT_DATE:
      return {
        ...state,
        arrivalDate: action.payload,
      };
    case SELECT_DURATION: {
      return {
        ...state,
        duration: action.payload,
      };
    }
    case SELECT_MONTH:
      return {
        ...state,
        month: action.payload,
      };
    case SELECT_GUESTS_ADULTS:
      return {
        ...state,
        adults: action.payload,
      };
    case SELECT_GUESTS_CHILDREN:
      return {
        ...state,
        children: action.payload,
      };
    case SELECT_GUESTS_UNDER5S:
      return {
        ...state,
        under5s: action.payload,
      };
    case SELECT_PET_FRIENDLY:
      return {
        ...state,
        petFriendly: action.payload,
      };
    case STORE_COTTAGE_DATES:
      return {
        ...state,
        cottageDates: action.payload,
      };
    case RESET_ARRIVAL:
      return {
        ...state,
        arrivalDate: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
