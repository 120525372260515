import { useContext } from 'react';
import PropTypes from 'prop-types';

import { createContext } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

// We have to create a bit of context to provide hasSignedUpForDeals,
// this lets both components share the state when it updates
const NewsletterContext = createContext();

export const NewsletterProvider = ({ children }) => {
  const [hasSignedUpForDeals, setHasSignedUpForDeals] = useLocalStorage('signedUpForDeals', false);

  return (
    <NewsletterContext.Provider value={{ hasSignedUpForDeals, setHasSignedUpForDeals }}>
      {children}
    </NewsletterContext.Provider>
  );
};

export const useNewsletter = () => useContext(NewsletterContext);

NewsletterProvider.propTypes = {
  children: PropTypes.node,
};
