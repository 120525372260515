import { useEffect, useMemo, useState } from 'react';
import { config } from '../../../config';
import { useLocation } from 'react-router-dom';

// Move this to its own hook if used else where in future
const useShowBasedOnScroll = () => {
  const [showBasedOnScroll, setShowBasedOnScroll] = useState(window.scrollY > 97);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Update the state directly based on the current scroll position
      setShowBasedOnScroll((prevShow) => {
        if (prevShow && currentScrollY <= 97) {
          return false;
        }
        if (!prevShow && currentScrollY > 97) {
          return true;
        }
        return prevShow;
      });
    };

    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return { showBasedOnScroll };
};

export const useShowOriginalBackground = () => {
  const { showBasedOnScroll } = useShowBasedOnScroll();
  const isHeaderStatic = config['header.static'];
  const { search } = useLocation();
  const isResultsPage = search.length > 0;

  const showOriginalBackground = useMemo(() => {
    if (isHeaderStatic) return true;
    if (isResultsPage) return false;
    return showBasedOnScroll;
  }, [isHeaderStatic, isResultsPage, showBasedOnScroll]);

  return { showOriginalBackground };
};
