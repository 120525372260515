import { Container, Row, Col } from 'react-bootstrap';
import { NoPopupSearchBox } from '../SearchBox/NoPopupSearchBox';
import PropTypes from 'prop-types';
import { INTERNAL_LOCATIONS } from '../../Constants';

const Banner = ({
  fluid = true,
  bg = 'secondary',
  text = 'white',
  content = 'Banner is active but no copy has been defined.',
  url = '#',
}) => {
  const shouldOpenInNewTab = !INTERNAL_LOCATIONS.some((prefix) => url.startsWith(prefix));
  const target = shouldOpenInNewTab ? '_blank' : '_self';
  const rel = shouldOpenInNewTab ? 'noopener noreferrer' : undefined;

  return (
    <>
      <Container fluid={fluid} className={`bg-${bg} p-3 p-lg-4`}>
        <a href={url} target={target} rel={rel} className="text-decoration-none">
          <Container>
            <Row>
              <Col>
                <p className={`text-${text} h3 text-center m-0`}>{content}</p>
              </Col>
            </Row>
          </Container>
        </a>
      </Container>
      <Container className="d-md-none" id="search-container">
        <Row className="bg-primary py-4">
          <Col>
            <NoPopupSearchBox />
          </Col>
        </Row>
      </Container>
    </>
  );
};

Banner.propTypes = {
  fluid: PropTypes.bool,
  bg: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.string,
  url: PropTypes.string,
};

export default Banner;
