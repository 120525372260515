import {
  CHANGE_PASSWORD,
  CREATE_USER,
  GET_BOOKINGS,
  LOGIN,
  LOGOUT,
  REQUEST_PASSWORD_RESET,
  UPDATE_USER,
} from './action-types';
import { getBookingsFailedSelector, getBookingsLoadedSelector, getBookingsPendingSelector } from './selectors';
import { apiRequest, callbackApiRequest } from '../apiRequest';
import selfCancellingAction from '../selfCancellingAction';
import { urls } from '../../../config';

export const [changePassword, cancelChangePassword] = selfCancellingAction((dispatch, email, passwd, resetCode) => {
  return callbackApiRequest(
    dispatch,
    CHANGE_PASSWORD,
    urls.passwordReset,
    { action: 'reset', email, passwd, resetCode },
    undefined,
    'post',
  );
});

export const [createUser, cancelCreateUser] = selfCancellingAction((dispatch, data) => {
  return callbackApiRequest(
    dispatch,
    CREATE_USER,
    urls.userSetDetails,
    { ...data, method: 'create' },
    undefined,
    'post',
  );
});

export const [getBookings, cancelGetBookings] = selfCancellingAction((dispatch, force) => {
  dispatch((d, getState) => {
    const state = getState();
    const hasFailed = getBookingsFailedSelector(state);
    const isLoaded = getBookingsLoadedSelector(state);
    const isLoading = getBookingsPendingSelector(state);

    if (!isLoaded && !isLoading && (!hasFailed || force)) {
      apiRequest(d, GET_BOOKINGS, urls.userGetBookings);
    }
  });
});

export const [login, cancelLogin] = selfCancellingAction((dispatch, email, password) => {
  return apiRequest(dispatch, LOGIN, urls.login, { email, passwd: password }, undefined, 'post');
});

export const [logout, cancelLogout] = selfCancellingAction((dispatch) => {
  return apiRequest(dispatch, LOGOUT, urls.logout);
});

export const [requestPasswordReset, cancelRequestPasswordReset] = selfCancellingAction((dispatch, email) => {
  return callbackApiRequest(
    dispatch,
    REQUEST_PASSWORD_RESET,
    urls.passwordReset,
    { action: 'request', email },
    undefined,
    'post',
  );
});

export const [updateUser, cancelUpdateUser] = selfCancellingAction((dispatch, data) => {
  return callbackApiRequest(
    dispatch,
    UPDATE_USER,
    urls.userSetDetails,
    { ...data, method: 'update' },
    undefined,
    'post',
  );
});
