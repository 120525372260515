import { APP_INIT, GET_INIT_DATA } from './action-types';
import { apiRequest, checkRequestNeeded } from '../apiRequest';
import { config, urls } from '../../../config';

import { isReady, isFetching, hasError } from '../../redux/init/selectors';

export const appInit = (dispatch) => {
  dispatch({ type: APP_INIT });
};

export const getInitData = (dispatch, force) => {
  dispatch((d, getState) => {
    const state = getState();
    const _isLoading = isFetching(state);
    const _isLoaded = isReady(state);
    const _hasError = hasError(state);

    if (checkRequestNeeded(true, _isLoading, _isLoaded, _hasError, force)) {
      apiRequest(d, GET_INIT_DATA, urls.init, { siteCode: config.siteCode });
    }
  });
};
