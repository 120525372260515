import { useEffect, useRef } from "react";
import _debounce from 'lodash.debounce';

export const useIsMobile = () => {
  const width = useRef(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = _debounce(() => (width.current = window.innerWidth), 100);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isMobile = width.current < 992;

  return { isMobile };
};