import { GET_NAV } from './action-types';
import { SUCCESS } from '../constants';

const defaultState = {
  nav: [],
  navExpiryTime: 0,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_NAV + SUCCESS:
      return {
        ...state,
        nav: action.payload.response.data,
        navExpiryTime: Date.now(), // add to this number to enable caching
      };
    default:
      return state;
  }
};

export default reducer;
