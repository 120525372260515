import { SELECT_CHANNEL, SELECT_DATE, SELECT_PARK } from './action-types';
import { START_SEARCH } from '../search/form/action-types';

const initialState = {
  channelId: undefined,
  parkId: undefined,
  selectedDate: undefined,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CHANNEL:
      return {
        ...state,
        channelId: action.payload,
      };
    case SELECT_PARK:
      return {
        ...state,
        parkId: action.payload,
      };
    case SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case START_SEARCH:
      return {
        ...state,
        selectedDate: action.payload.arrivalDate,
      };
    default:
      return state;
  }
};

export default reducer;
