import { GET_PARK_RESULTS } from './action-types';
import { GET_RESULTS } from '../../../search/results/action-types';
import { SUCCESS } from '../../../constants';

const initialState = {
  parkResults: {},
};

function processResults(data) {
  const output = { ...data };
  delete output['unitTypes'];
  output.timeLoaded = Date.now();
  return output;
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULTS + SUCCESS:
      return initialState;
    case GET_PARK_RESULTS + SUCCESS:
      return {
        ...state,
        parkResults: {
          ...state.parkResults,
          [action.payload.params.parkId]: processResults(action.payload.response.data.data),
        },
      };
    default:
      return state;
  }
};

export default reducer;
