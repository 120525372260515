import { DDMMYYYYToDate } from './date';

// This function takes in some prices such as [{2: 100}, {4: 150}, {6: 200}]
// if partySize is undefined, 0, 1 or 2 it should return 100
// if partySize is 3 or 4 it should return 150
// if partySize is 5 or 6 it should return 200
// above that return undefined
export function getMinPriceFromPartySize(obj, partySize) {
  let newPrice = Object.values(obj?.prices?.[0])[0];

  obj.prices.forEach((priceObj, i) => {
    const pricePartySize = Number(Object.keys(priceObj)[0]);
    const nextPrice = obj?.prices?.[i + 1] ? Object.values(obj?.prices?.[i + 1])[0] : undefined;

    if (partySize > pricePartySize) {
      newPrice = nextPrice;
    }
  });

  return { ...obj, price: newPrice };
}

export const applyBasicFiltersToDataset = (dataSet, duration, petFriendly, partySize) => {
  return Object.values(dataSet)
    .flat()
    .filter(
      (obj) =>
        obj.nights === duration &&
        (petFriendly ? obj.pets.includes('Y') : true) &&
        partySize <= Math.max(...obj.prices.map((price) => Math.max(...Object.keys(price).map(Number)))),
    );
};

// dateStr = dd/mm/yyyy
// dateObj is just a date
function areDatesEqual(dateStr, dateObj) {
  // Parse the date string (format: DD/MM/YYYY) into a Date object
  const [day, month, year] = dateStr.split('/').map(Number);
  const parsedDate = new Date(year, month - 1, day);

  // Check if both dates represent the same day
  return (
    parsedDate.getFullYear() === dateObj.getFullYear() &&
    parsedDate.getMonth() === dateObj.getMonth() &&
    parsedDate.getDate() === dateObj.getDate()
  );
}

export const filterDataByMonthYear = (data, month, year, duration, petFriendly, partySize) => {
  const basicFilteredData = applyBasicFiltersToDataset(data, duration, petFriendly, partySize);
  return basicFilteredData.filter((obj) => obj.date.split('/')[1] === month && obj.date.split('/')[2] === year);
};

export const filterDataByExactDate = (data, date, duration, petFriendly, partySize) => {
  const basicFilteredData = applyBasicFiltersToDataset(data, duration, petFriendly, partySize);
  return basicFilteredData.filter((obj) => areDatesEqual(obj.date, new Date(date)))
};

const sortDataByDate = (data) => {
  return data.sort((a, b) => {
    const dateA = a.date.split('/').reverse().join('');
    const dateB = b.date.split('/').reverse().join('');
    return dateA.localeCompare(dateB);
  });
};

export function minIgnoringNaN(...numbers) {
  const validNumbers = numbers.filter((number) => !isNaN(number));
  return validNumbers.length > 0 ? Math.min(...validNumbers) : NaN;
}

// Map over the data and store the cheapest price for each date
// The map will be a collection of { dates: price }
export const getMinPricesForEachDate = (holidaysWithMinPrice) => {
  const minPriceForDateMap = new Map();

  holidaysWithMinPrice.forEach((holiday) => {
    const currentPrice = parseFloat(holiday.price);
    if (minPriceForDateMap.has(holiday.date)) {
      const existingPrice = minPriceForDateMap.get(holiday.date);
      const newLowestPrice = minIgnoringNaN(existingPrice, currentPrice);
      minPriceForDateMap.set(holiday.date, newLowestPrice);
    } else {
      minPriceForDateMap.set(holiday.date, currentPrice);
    }
  });

  return minPriceForDateMap;
};

// Now we know the cheapest holidays for each date, we loop over the main data
// if the holidays date is in the dateMap object and the price matches we push it to the final array
const getCheapestHolidaysForEachDate = (holidaysWithMinPrice, minimumPriceForEachDate) => {
  const allRegionsMinPricesWithoutDuplicates = [];
  holidaysWithMinPrice.forEach((holiday) => {
    if (
      minimumPriceForEachDate.has(holiday.date) &&
      parseFloat(holiday.price) === minimumPriceForEachDate.get(holiday.date)
    ) {
      allRegionsMinPricesWithoutDuplicates.push(holiday);
      minimumPriceForEachDate.delete(holiday.date);
    }
  });
  return allRegionsMinPricesWithoutDuplicates;
};

/**
 * Filters all regions 'cached availability' data based on selected month/duration/pets. Return array of objects
 * @param {object} cachedDates
 * @param {string} month
 * @param {string} year
 * @param {string} duration
 * @param {boolean} petFriendly
 * @param {number} partySize
 */
export const getFilteredRegionDates = (baseData, month, year, duration, petFriendly, partySize) => {
  // Filter out all of the data we don't want, sort it based on date and then add a price based on party size
  const filteredHolidays = filterDataByMonthYear(baseData, month, year, duration, petFriendly, partySize);
  const sortedData = sortDataByDate(filteredHolidays);
  const holidaysWithMinPrice = sortedData.map((obj) => getMinPriceFromPartySize(obj, partySize));

  const minimumPriceForEachDate = getMinPricesForEachDate(holidaysWithMinPrice);

  return getCheapestHolidaysForEachDate(holidaysWithMinPrice, minimumPriceForEachDate);
};

/**
 * Filters all regions 'cached availability' data based on selected month/duration/pets. Return array of objects
 * @param {object} cachedDates
 * @param {string} date
 * @param {string} duration
 * @param {boolean} petFriendly
 * @param {number} partySize
 */
export const getFilteredRegionDatesWholeDate = (baseData, date, duration, petFriendly, partySize) => {
  // Filter out all of the data we don't want, sort it based on date and then add a price based on party size
  const filteredHolidays = filterDataByExactDate(baseData, date, duration, petFriendly, partySize);
  const sortedData = sortDataByDate(filteredHolidays);
  const holidaysWithMinPrice = sortedData.map((obj) => getMinPriceFromPartySize(obj, partySize));

  const minimumPriceForEachDate = getMinPricesForEachDate(holidaysWithMinPrice);

  return getCheapestHolidaysForEachDate(holidaysWithMinPrice, minimumPriceForEachDate);
};

/**
 * Returns a new object of min prices with dates which are between a given range of dates
 * @param {object} cachedDates
 * @param {date} startDate
 * @param {date} endDate
 */
export const getMinPricesBetweenDates = (cachedDates, startDate, endDate) => {
  const output = {};
  Object.keys(cachedDates).forEach((regionId) => {
    output[regionId] = cachedDates[regionId].filter(
      (item) => DDMMYYYYToDate(item.date) >= startDate && DDMMYYYYToDate(item.date) <= endDate,
    );
  });
  return output;
};

/** Returns an array with minimum price for each duration;
 * @param {array} array
 */
export function getMinPrices(cachedData) {
  const newMap = new Map();
  cachedData.forEach((item) => {
    const price = parseFloat(Object.values(item.prices?.[0]));
    if (!newMap.has(item.nights)) {
      newMap.set(item.nights, price);
    }
    newMap.set(item.nights, minIgnoringNaN(newMap.get(item.nights), price));
  });

  const mapConvertedToArray = Array.from(newMap, ([nights, price]) => ({
    nights,
    price,
  }));

  return mapConvertedToArray;
}

export const filterCachedAvailLateDeals = (_lateDeals) => {
  if (_lateDeals && Object.keys(_lateDeals).length > 0) {
    const today = new Date();
    const lateDeal3 = [];
    const lateDeal4 = [];
    const lateDeal7 = [];
    _lateDeals.forEach((deal) => {
      let dateArr = deal.date.split('/');
      let dateToCompare = new Date(parseInt(dateArr[2]), parseInt(dateArr[1]) - 1, parseInt(dateArr[0]));

      if (deal.nights === '3' && lateDeal3.length < 3 && dateToCompare > today) {
        lateDeal3.push(deal);
      }
      if (deal.nights === '4' && lateDeal4.length < 3 && dateToCompare > today) {
        lateDeal4.push(deal);
      }
      if (deal.nights === '7' && lateDeal7.length < 3 && dateToCompare > today) {
        lateDeal7.push(deal);
      }
    });

    const mapToReturn = new Map();

    mapToReturn.set(3, lateDeal3);
    mapToReturn.set(4, lateDeal4);
    mapToReturn.set(7, lateDeal7);

    return mapToReturn;
  }
  return null;
};
