import PropTypes from 'prop-types';

import InputRadioGroup from '../../../FormElements/InputRadioGroup';
import InputCheckbox from '../../../FormElements/InputCheckbox';
import t from '../../../../../text';
import { config } from '../../../../../config';
import { Form } from 'react-bootstrap';

export const ContactPreferencesInputs = (props) => (
  <div className="contact-preferences">
    <h4>{!props.hideTitle && t('forms.contactPrefs.title')}</h4>
    <p className="mb-1">{t('forms.contactPrefs.offers')}</p>
    {config['user.optin.emailOpt'] && (
      <Form.Group md={1.5} className="p-0">
        <label className="input-label">{t('forms.contactPrefs.emailOpt')}</label>
        <div className="d-inline-block">
          <InputCheckbox
            id="emailOpt-Y"
            name="emailOpt"
            label={t('forms.forms.yes')}
            ariaLabel={t('forms.forms.yes')}
            checked={props.values.emailOpt === 'Y'}
            onChange={() => props.onChange('emailOpt', 'Y')}
          />
        </div>
        <div className="d-inline-block">
          <InputCheckbox
            id="emailOpt-N"
            name="emailOpt"
            label={t('forms.forms.no')}
            ariaLabel={t('forms.forms.no')}
            checked={props.values.emailOpt === 'N'}
            onChange={() => props.onChange('emailOpt', 'N')}
          />
        </div>
        {props.errors?.emailOpt && <div className="invalid-feedback d-block">{props.errors?.emailOpt}</div>}
      </Form.Group>
    )}
    {config['user.optin.smsOpt'] && (
      <Form.Group md={1.5} className="p-0">
        <label className="input-label">{t('forms.contactPrefs.smsOpt')}</label>
        <div className="d-inline-block">
          <InputCheckbox
            id="smsOpt-Y"
            name="smsOpt"
            label={t('forms.forms.yes')}
            ariaLabel={t('forms.forms.yes')}
            checked={props.values.smsOpt === 'Y'}
            onChange={() => props.onChange('smsOpt', 'Y')}
          />
        </div>
        <div className="d-inline-block">
          <InputCheckbox
            id="smsOpt-N"
            name="smsOpt"
            label={t('forms.forms.no')}
            ariaLabel={t('forms.forms.no')}
            checked={props.values.smsOpt === 'N'}
            onChange={() => props.onChange('smsOpt', 'N')}
          />
        </div>
        {props.errors?.smsOpt && <div className="invalid-feedback d-block">{props.errors?.smsOpt}</div>}
      </Form.Group>
    )}
    {config['user.optin.thirdOpt'] && (
      <InputRadioGroup
        groupClassName="mb-4"
        invalid={!!props.errors?.thirdOpt}
        name="thirdOpt"
        onChange={props.onChange}
        options={[
          { label: t('forms.forms.yes'), value: 'Y' },
          { label: t('forms.forms.no'), value: 'N' },
        ]}
        text={t('forms.contactPrefs.emailNewspaperOffers')}
        validationMessage={props.errors?.thirdOpt}
        values={props.values}
      />
    )}
  </div>
);

ContactPreferencesInputs.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
  onChange: PropTypes.func,
};

export default ContactPreferencesInputs;
