import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export const SelectInput = ({
  controlId,
  name,
  label,
  defaultValue,
  value,
  hideLabel,
  onChange,
  children,
  invalid,
  validationMessage,
  groupClassName = '',
  labelClassName = '',
  className = 'col',
  attrs = {},
}) => (
  <Form.Group className={groupClassName} controlId={controlId || name}>
    {label && <Form.Label className={`${labelClassName} ${hideLabel ? 'invisible' : ''} `}>{label}</Form.Label>}

    <Form.Control
      as="select"
      className={`${className} ${invalid ? 'is-invalid' : ''}`}
      name={name}
      defaultValue={defaultValue}
      value={value}
      {...attrs}
      onChange={onChange}
      custom
    >
      {children}
    </Form.Control>

    {invalid && <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>}
  </Form.Group>
);

SelectInput.propTypes = {
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  controlId: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  name: PropTypes.string,
  attrs: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectInput;
